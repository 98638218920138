import { useState } from 'react';

const articles = [
  {
    id: 1,
    title: '易经入门：基础概念解析',
    description: '了解阴阳、八卦、六十四卦等易经基础概念',
    category: '基础'
  },
  {
    id: 2,
    title: '卦象解读方法详解',
    description: '学习如何正确理解和运用卦象信息',
    category: '进阶'
  },
  {
    id: 3,
    title: '时间起卦法详解',
    description: '掌握使用时间信息进行起卦的方法',
    category: '技巧'
  }
];

export default function Academy() {
  const [selectedCategory, setSelectedCategory] = useState<string>('全部');
  const categories = ['全部', '基础', '进阶', '技巧'];
  
  const filteredArticles = selectedCategory === '全部'
    ? articles
    : articles.filter(article => article.category === selectedCategory);

  return (
    <div className="py-6">
      <div className="mb-8 flex space-x-3 overflow-x-auto pb-2">
        {categories.map(category => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={`px-6 py-2.5 rounded-full text-sm font-medium whitespace-nowrap transition-colors ${
              selectedCategory === category
                ? 'bg-red-600 text-white shadow-sm'
                : 'bg-white text-gray-600 hover:bg-gray-50 border border-gray-200'
            }`}
          >
            {category}
          </button>
        ))}
      </div>

      <div className="space-y-4">
        {filteredArticles.map(article => (
          <div
            key={article.id}
            className="bg-white rounded-xl shadow-sm p-6 hover:shadow-md transition-shadow cursor-pointer"
          >
            <h3 className="text-lg font-semibold text-gray-900">{article.title}</h3>
            <p className="mt-2 text-gray-600">{article.description}</p>
            <span className="mt-3 inline-block px-3 py-1 text-sm bg-red-50 text-red-600 rounded-full font-medium">
              {article.category}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}