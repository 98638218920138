import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import HexagramDisplay from './HexagramDisplay';
import { hexagramNames } from '../data/hexagramNames';
import type { HexagramResult } from '../types/iching';

interface DivinationResultProps {
  result: HexagramResult;
}

export default function DivinationResult({ result }: DivinationResultProps) {
  const navigate = useNavigate();

  const handleHexagramClick = (binary: string) => {
    navigate(`/hexagram/${binary}`);
  };

  const handleYaoTextClick = (binary: string, index: number) => {
    navigate(`/hexagram/${binary}/text/${index}`);
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="flex flex-col items-center">
          <HexagramDisplay
            binary={result.mainHexagram}
            title={hexagramNames[result.mainHexagram]}
            movingYao={result.movingYao}
            onClick={() => handleHexagramClick(result.mainHexagram)}
          />
          <h3 className="text-lg font-medium text-gray-900 mt-2">主卦</h3>
        </div>
        <div className="flex flex-col items-center">
          <HexagramDisplay
            binary={result.changedHexagram}
            title={hexagramNames[result.changedHexagram]}
            onClick={() => handleHexagramClick(result.changedHexagram)}
          />
          <h3 className="text-lg font-medium text-gray-900 mt-2">变卦</h3>
        </div>
      </div>
      
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="flex flex-col items-center">
          <HexagramDisplay
            binary={result.useHexagram}
            onClick={() => handleHexagramClick(result.useHexagram)}
          />
          <h3 className="text-lg font-medium text-gray-900 mt-2">用互卦</h3>
        </div>
        <div className="flex flex-col items-center">
          <HexagramDisplay
            binary={result.bodyHexagram}
            onClick={() => handleHexagramClick(result.bodyHexagram)}
          />
          <h3 className="text-lg font-medium text-gray-900 mt-2">体互卦</h3>
        </div>
      </div>

      <div className="mt-6 space-y-4">
        <div>
          <h3 className="text-lg font-bold text-gray-900 mb-2">卦辞</h3>
          <p className="text-gray-600">{result.mainText}</p>
        </div>

        <div>
          <h3 className="text-lg font-bold text-gray-900 mb-2">爻辞</h3>
          <div className="space-y-2">
            {result.yaoTexts.map((text, index) => (
              <p
                key={index}
                className={clsx(
                  'text-gray-600 p-2 rounded hover:bg-gray-50 cursor-pointer',
                  result.movingYao === index && 'bg-red-50 text-red-700'
                )}
                onClick={() => handleYaoTextClick(result.mainHexagram, index)}
              >
                {text}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}