import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../stores/authStore';
import { useDivinationStore } from '../stores/divinationStore';
import HexagramDisplay from '../components/HexagramDisplay';
import { hexagramNames } from '../data/hexagramNames';
import { getRandomQuote } from '../data/academyQuotes';
import { getRoleDisplayName } from '../utils/roleMapping';
import dayjs from 'dayjs';
import { ArrowLeftIcon, TrashIcon } from '@heroicons/react/24/outline';
import * as authApi from '../api/auth';

export default function Profile() {
  const navigate = useNavigate();
  const { user, logout } = useAuthStore();
  const { savedResults, loadSavedResult, deleteSavedResult } = useDivinationStore();
  const [invitationCode, setInvitationCode] = useState<{ code: string; expiresAt: string } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [quote, setQuote] = useState('');

  useEffect(() => {
    setQuote(getRandomQuote());
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleResultClick = (result: any) => {
    loadSavedResult(result);
    navigate('/divination');
  };

  const handleDelete = (e: React.MouseEvent, timestamp: string) => {
    e.stopPropagation();
    if (window.confirm('确定要删除这条卦例记录吗？')) {
      deleteSavedResult(timestamp);
    }
  };

  const handleGetInvitationCode = async () => {
    setIsLoading(true);
    try {
      const response = await authApi.getInvitationCode();
      setInvitationCode(response);
      await navigator.clipboard.writeText(response.code);
    } catch (error) {
      console.error('Failed to get invitation code:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-stone-50">
      <header className="bg-white shadow-sm">
        <div className="max-w-lg mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center space-x-4">
              <button
                onClick={() => navigate(-1)}
                className="p-1 rounded-full hover:bg-gray-100"
              >
                <ArrowLeftIcon className="w-5 h-5 text-gray-600" />
              </button>
              <h1 className="text-xl font-bold text-gray-900">个人中心</h1>
            </div>
            <button
              onClick={handleLogout}
              className="px-4 py-2 text-sm border border-red-600 text-red-600 rounded-full hover:bg-red-50 transition-colors"
            >
              退出登录
            </button>
          </div>
        </div>
      </header>

      <main className="max-w-lg mx-auto px-4 py-6">
        <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
          <div className="flex items-center space-x-4">
            <img 
              src="/avatar.png"
              alt={user?.username || '用户头像'}
              className="w-12 h-12 rounded-full object-cover"
            />
            <div className="flex-1">
              <div className="flex items-center space-x-2">
                <h2 className="text-lg font-medium text-gray-900">{user?.username}</h2>
                <span className="px-2 py-1 bg-red-50 text-red-600 text-xs rounded-full">
                  {user?.role ? getRoleDisplayName(user.role) : '普通会员'}
                </span>
              </div>
              <p className="text-sm text-gray-500 mt-1 italic">{quote}</p>
            </div>
          </div>

          {user?.role === 'superadmin' && (
            <div className="mt-6 space-y-4">
              <button
                onClick={handleGetInvitationCode}
                disabled={isLoading}
                className="w-full py-2 px-4 border border-red-600 rounded-md text-red-600 hover:bg-red-50 transition-colors disabled:opacity-50"
              >
                {isLoading ? '获取中...' : '获取邀请码'}
              </button>
              
              {invitationCode && (
                <div className="p-4 bg-green-50 rounded-lg">
                  <p className="text-green-600">
                    邀请码已复制到剪贴板：{invitationCode.code}
                  </p>
                  <p className="text-sm text-green-500 mt-1">
                    有效期至：{dayjs(invitationCode.expiresAt).format('YYYY-MM-DD HH:mm:ss')}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="bg-white rounded-xl shadow-sm overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-100">
            <h3 className="text-lg font-medium text-gray-900">历史卦例</h3>
          </div>
          
          {savedResults.length === 0 ? (
            <div className="p-8 text-center">
              <div className="w-16 h-16 mx-auto mb-4 bg-gray-100 rounded-full flex items-center justify-center">
                <svg className="w-8 h-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
              </div>
              <p className="text-gray-500">暂无历史卦例</p>
              <button
                onClick={() => navigate('/divination')}
                className="mt-4 px-4 py-2 text-sm text-red-600 hover:text-red-700 font-medium"
              >
                开始起卦
              </button>
            </div>
          ) : (
            <div className="divide-y divide-gray-100">
              {savedResults.map((result, index) => (
                <div
                  key={index}
                  onClick={() => handleResultClick(result)}
                  className="p-4 hover:bg-gray-50 cursor-pointer transition-colors relative"
                >
                  <div className="flex items-center justify-between mb-3">
                    <span className="text-sm text-gray-500">
                      {dayjs(result.timestamp).format('YYYY年MM月DD日 HH:mm')}
                    </span>
                    <div className="flex items-center space-x-2">
                      <span className="text-xs px-2 py-1 bg-red-50 text-red-600 rounded-full">
                        查看详情
                      </span>
                      <button
                        onClick={(e) => handleDelete(e, result.timestamp)}
                        className="p-1 text-gray-400 hover:text-red-600 rounded-full hover:bg-red-50"
                      >
                        <TrashIcon className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                  <div className="flex justify-around">
                    <HexagramDisplay
                      binary={result.mainHexagram}
                      title={hexagramNames[result.mainHexagram]}
                      subtitle="主卦"
                      movingYao={result.movingYao}
                      size="small"
                    />
                    <div className="flex items-center">
                      <svg className="w-4 h-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                      </svg>
                    </div>
                    <HexagramDisplay
                      binary={result.changedHexagram}
                      title={hexagramNames[result.changedHexagram]}
                      subtitle="变卦"
                      size="small"
                    />
                  </div>
                  <div className="mt-3 text-sm text-gray-600 flex items-center justify-between">
                    <div className="space-x-2">
                      <span className="inline-flex items-center px-2 py-1 bg-gray-100 rounded-full text-xs">
                        上卦：{result.upperTrigram}
                      </span>
                      <span className="inline-flex items-center px-2 py-1 bg-gray-100 rounded-full text-xs">
                        下卦：{result.lowerTrigram}
                      </span>
                      <span className="inline-flex items-center px-2 py-1 bg-gray-100 rounded-full text-xs">
                        动爻：{result.movingYaoPosition}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </main>
    </div>
  );
}